.App {
  background-image: url('/public/MyNFA_blue_background.png');
  background-size: 100% ;
  background-repeat: no-repeat;
  background-color: #00104c;
  color: white;
  text-align: center;
  min-width: 400px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  padding: 80px 20px 20px 20px;
}

a:link {text-decoration: none;}
a:hover {text-decoration: underline; cursor: pointer}

a:link, a:visited {
  color: #61dafb;
}

.App-link {
  color: #61dafb;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.flexRow {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.justifyStart {
  justify-content: flex-start;
}

.alignStart {
  align-items: flex-start;
}

.gap-0 {gap: 0;}
.gap-1 {gap: 0.5em;}
.gap-2 {gap: 15px;}
.gap-3 {gap: 30px;}
.gap-4 {gap: 60px;}

.mb-0 {margin-bottom: 0;}
.mb-1 {margin-bottom: 0.5em;}
.mb-2 {margin-bottom: 1em;}
.mb-3 {margin-bottom: 2em;}
.mb-4 {margin-bottom: 4em;}

.mt-0 {margin-top: 0;}
.mt-1 {margin-top: 0.5em;}
.mt-2 {margin-top: 1em;}
.mt-3 {margin-top: 2em;}
.mt-4 {margin-top: 4em;}

.title {
  font-size: 32px;
  margin-bottom: 0.5em;
}

.title-mb0 {
  font-size: 32px;
}

.heading {
  font-size: 22px;
}

.buttonWide {
  width: 150px;
}

.buttonExtraWide {
  width: 300px;
}

.bold {
  font-weight: bold;
}

.errorText {
  color: #d32f2f;
  font-size: 1rem;
}

.infoPage {
  height: 10em;
  width: 20em;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: lightgreen;
  border-radius: 15px;
  color: midnightblue;
}

.termsAndConditions {
  text-align: left;
  height: 20em;
  overflow-Y: scroll;
  padding: 10px 20px 10px 20px;
  background-color: slategrey;
  border-radius: 15px;
}

.dashboardPanel {
  min-height: 6em;
  min-width: 15em;
  padding: 10px 20px;
  background-color: #22ddd2;
  color: #00104c;
  border-radius: 15px;
}

.vaultPanel {
  min-height: 6em;
  min-width: 15em;
  padding: 10px 20px;
  background-color: #22ddd2;
  color: #00104c;
  border-radius: 15px;
}

.vaultItem {
  margin: 1em;
  min-height: 6em;
  min-width: 15em;
  padding: 10px 20px;
  background-color: #88a0ec;
  color: black;
  border-radius: 5px;
}

.selfiePanel {
  padding: 10px 20px;
  background-color: white;
  color: #00104c;
  border-radius: 15px;
}

.dataButtonActive {
  background-color: #77d353;
  min-width: 150px;
  min-height: 40px;
  padding: 5px 10px;
  border-radius: 15px;
}

.dataButton {
  background-color: white;
  min-width: 150px;
  min-height: 40px;
  padding: 5px 10px;
  border-radius: 15px;
}

.completed {
  color: limegreen;
}

.dotEmpty {
  width: 0.75em;
  height: 0.75em;
  border-radius: 0.5em;
  border: solid 2px lightgreen;
}

.dotFilled {
  width: 0.75em;
  height: 0.75em;
  border-radius: 0.5em;
  border: solid 2px lightgreen;
  background-color: lightgreen;
}
